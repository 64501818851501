import * as React from 'react';
import { Contact } from '../../components/contact';
import Footer from '../../components/footer';
import Header from '../../components/header';
import SEO from '../../components/seo';

export default function Index() {
    return (
        <>
            <SEO title="FAQ" description="Common questions" />
            <Header />
            <section id="faqs" className="mt-32 p-8">
                <div className="text-center text-6xl text-pink-500 mb-4">
                    <strong>Frequent asked questions</strong>
                </div>
                <div className="text-center text-2xl text-secondary">Common mote questions</div>
            </section>
            <Contact />
            <Footer />
        </>
    );
}
